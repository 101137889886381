import { useRef, useState, useCallback } from "react";

import {
  FaCaretLeft,
  FaCaretRight,
  FaPlus,
  FaCircleXmark,
  FaSquare,
  FaCircle,
  FaArrowRight,
} from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import { useMemo } from "react";
import Notification from "../component/utility/Notification";
import { activateAssortment, deActivateAssortment, deleteAssortment, getAssortmentById, getAssortmentList, saveAssortment, updateAssortment } from "../service/AssortmentService";
import { getAllActivePlanList } from "../service/PlanService";
import { getActiveClusterListByPlanId } from "../service/ClusterService";
import { getActiveCategoryList, getActiveCategoryListOrderByAsc } from "../service/CategoryService";
import { getActiveItemListByCategoryId, getItemById } from "../service/ItemService";
import { deleteAssortmentItemsByAssortmentId, getAssortmentItemListByAssortmentId, saveAssortmentItemList, updateAssortmentItemList } from "../service/AssortmentItemService";


export default function Assortment() {
  const loggedUser = JSON.parse(
    atob(sessionStorage.getItem("x$sk72hyQwersgyhk"))
  );

  /****** Left Pane - start ***************************************************************/

  const [leftPaneStyle, setLeftPaneStyle] = useState("w-1/5");
  const [isLeftPaneExpanded, setIsLeftPaneExpanded] = useState(false);
  const [leftPaneExpandedButtonIcon, setLeftPaneExpandedButtonIcon] = useState(
    <FaCaretRight size={36} />
  );

  //++++ Assortment DataTable  ++++++++++++++++++++++++++++++++
  const assortmentTableRef = useRef();
  const [paginationlHide, setPaginationlHide] = useState(true);
  const [assortmentTableRowData, setAssortmentTableRowData] = useState([]);

  const [assortmentTableColumnDefs, setAssortmentTableColumnDefs] = useState([
    { colId: "id", valueGetter: (r) => r.data.id, flex: 1, hide: true },   
    {
      colId: "code",
      headerName: "Assortment Code",
      valueGetter: (r) => r.data.code,
      filter: true,
      flex: 1,
    },
    {
      colId: "createdAt",
      headerName: "Created Date",
      valueGetter: (r) => r.data.createdAt.replace("T", " "),
      flex: 1,
      hide: true,
    },
    {
      colId: "active",
      headerName: "Status",
      valueGetter: (r) => r.data.active,
      filter: true,
      flex: 1,
      hide: true,
    },
  ]);

  // functions
  const handleExpandLeftPane = () => {
    if (!isLeftPaneExpanded) setIsLeftPaneExpanded(true);
    else setIsLeftPaneExpanded(false);

    if (isLeftPaneExpanded) {
      setLeftPaneStyle("w-1/5");
      setLeftPaneExpandedButtonIcon(<FaCaretRight size={36} />);

      assortmentTableRef.current.api.setColumnsVisible(
        ["createdAt", "active"],
        false
      );
      setPaginationlHide(true);
    } else {
      setLeftPaneStyle("w-screen");
      setLeftPaneExpandedButtonIcon(<FaCaretLeft size={36} />);

      assortmentTableRef.current.api.setColumnsVisible(
        ["createdAt", "active"],
        true
      );
      setPaginationlHide(false);
    }
  };

  const fetchAssortmentTableData = async () => {
    try {
      const res = await getAssortmentList(loggedUser.token);
      
      if (res !== undefined) {        
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].index = i + 1;
        }
        setAssortmentTableRowData(res.data);
      } else {
        setAssortmentTableRowData([]);
      }
    } catch (error) {
      console.error("Assortment -> fetchAssortmentTableData: ", error);
    }
  };

  useEffect(() => {
    fetchAssortmentTableData();
  }, []);

  const handledOnRowClick = useCallback( async() => {
    const selectedRows = assortmentTableRef.current.api.getSelectedRows();

    if (selectedRows.length === 1) {

      let resAssortment = await getAssortmentById(loggedUser.token,selectedRows[0].id);

      fetchActiveClusterList(resAssortment.data.cluster.plan.id)

      setAssortmentFormData((prevState) => ({
        ...prevState,
        id: resAssortment.data.id,
        code: resAssortment.data.code,
        active: resAssortment.data.active,
        clusterId: resAssortment.data.cluster.id,
        planId: resAssortment.data.cluster.plan.id,
      }));

      

      setOperationMode("update");
      setApiResponse({
        status: "",
        message: "Finished loading : " + selectedRows[0].code,
      });
      setAssortmentStatus(selectedRows[0].active);
      setIsValidBasicInputs(true);
      setIsValidAssortmentItemInputs(true)

      // fetch assortment items 
      fetchAssortmentItemTableData(resAssortment.data.id);

      // assortment item inputs disabled
      setIsAssortmentItemInputDisabled(true);
    }
  }, []);

  /****** Left Pane - end ***************************************************************/

  /****** Right Pane - start ***************************************************************/
  //++++ Button Container  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [operationMode, setOperationMode] = useState("new"); // "new", "save", or "update"
  const [apiResponse, setApiResponse] = useState({
    //status: true, data: null, message: 'Already added : Fabric'
    status: "",
    message: "",
  });

  const [assortmentStatus, setAssortmentStatus] = useState("Active");
  
  const handleNewButton = () => {
    initialize();
    setApiResponse({ status: "", message: "Add new assortment" });
  };

  const handleSaveButton = async (e) => {
    
    e.preventDefault();
    setIsValidBasicInputs(true)
    setIsValidAssortmentItemInputs(true)

    let assortment = {
      id: assortmentFormData.id,
      code: assortmentFormData.code.toUpperCase(),
      active: assortmentFormData.active,
      clusterId: assortmentFormData.clusterId
    };
    

    let resAssortment, resAssortmentItem = null;
    let assortmentItemList = []

    if (isValidBasicInputs()) {
      if (operationMode === "new") {
        
        resAssortment = await saveAssortment(loggedUser.token, assortment)

        if (resAssortment.status) {

          assortmentItemTableRowData.forEach(row =>{
            let assortmentItem = {
              id: 0,
              assortmentId: resAssortment.data.id,
              itemId: row.itemId,
              quantity: row.quantity,
              isFree: row.isFree
            }

            assortmentItemList.push(assortmentItem)      
          });

          resAssortmentItem = await saveAssortmentItemList(loggedUser.token, assortmentItemList);

        }

        setApiResponse({ status: resAssortment.status, message: resAssortment.message });

        if (resAssortmentItem != null) {
          if (resAssortment.status & resAssortmentItem.status) {
            initialize();
          }
        }

      } else if (operationMode === "update") {

        resAssortment = await updateAssortment(loggedUser.token,assortment)

        if (resAssortment.status) {

          assortmentItemTableRowData.forEach(row =>{
            let assortmentItem = {
              id: row.id,
              assortmentId: resAssortment.data.id,
              itemId: row.itemId,
              quantity: row.quantity,
              isFree: row.isFree
            }

            assortmentItemList.push(assortmentItem)      
          });

          resAssortmentItem = await updateAssortmentItemList(loggedUser.token,resAssortment.data.id,assortmentItemList)

        }

        setApiResponse({ status: resAssortment.status, message: resAssortment.message });

        if (resAssortmentItem != null) {
          if (resAssortment.status & resAssortmentItem.status) {
            initialize();
          }
        }
      } else {
        setApiResponse({ status: false, message: "Error" });
      }
    } else {
      setApiResponse({
        status: false,
        message: "Error saving : Invalid inputs",
      });
    }

    fetchAssortmentTableData();
    
  };

  const handleDeleteButton = async (e) => {
    
    e.preventDefault();
    let res = null;

    if (assortmentFormData.code !== "" || assortmentFormData.code.length !== 0) {
      res = await deleteAssortmentItemsByAssortmentId(loggedUser.token,assortmentFormData.id)
      if(res.status){
        res = await deleteAssortment(loggedUser.token,assortmentFormData.id)
      }
      setApiResponse({ status: res.status, message: res.message });
      if (res.status) {
        clearFormInputs();
      } else {
        setApiResponse({ status: false, message: "Error deleting..." });
      }
    }

    fetchAssortmentTableData();
    
  };

  const handleActiveButton = async (e) => {
    e.preventDefault();
    let res = null;
    
    if (assortmentStatus === "Active") {
      res = await deActivateAssortment(loggedUser.token,assortmentFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      setAssortmentStatus("Inactive");
    }

    if (assortmentStatus === "Inactive") {
      res = await activateAssortment(loggedUser.token,assortmentFormData.id)
      setApiResponse({ status: res.status, message: res.message });
      setAssortmentStatus("Active");
    }

    fetchAssortmentTableData();
    
  };

  //++++ Assortment info Tab Pane  +++++++++++++++++++++++++++++++++++++++++++++++++++++

  //++++ Assortment Item DataTable  ++++++++++++++++++++++++++++++++
  const assortmentItemTableRef = useRef();
  const [assortmentItemTableRowData, setAssortmentItemTableRowData] = useState([]);

  const [assortmentItemTableColumnDefs, setAssortmentItemTableColumnDefs] = useState([
    
    { colId: "id", valueGetter: (r) => r.data.id, flex: 1, hide: true },
    { colId: "itemId", valueGetter: (r) => r.data.itemId, flex: 1, hide: true },            
    {
      colId: "itemCode",
      headerName: "Item Code",
      valueGetter: (r) => r.data.itemCode,     
      flex: 0.3,     
    },
    {
      colId: "name",
      headerName: "Name",
      valueGetter: (r) => r.data.itemName,
      flex: 1
    },
    {
      colId: "quantity",
      headerName: "Quantity",
      valueGetter: (r) => r.data.quantity,
      cellStyle: { textAlign: 'center' },      
      flex: 0.3,
    },
    {
      colId: "free",
      headerName: "Free",           
      cellRenderer: (param)=>(param.data.isFree?<span className="px-4 py-0.4 text-sm font-semibold text-white bg-sky-600 rounded-lg">{param.data.free}</span>:''),           
      flex: 0.3,
    },
    {
      colId: "delete",
      headerName: "Delete",
      cellRenderer: (param)=>(<button type="button" onClick={()=> handledAssortmentItemTableRowDeleteButton(param)}><FaCircleXmark size={17} color="red"/></button>),
      cellStyle: { textAlign: 'center' },      
      flex: 0.25,
    },
    
  ]);

  

  const fetchAssortmentItemTableData = async (assortmentId) => {
    
    try {
      setAssortmentItemTableRowData([]);
      const res = await getAssortmentItemListByAssortmentId(loggedUser.token,assortmentId);

      if (res !== undefined) {
        for (let i = 0; i < res.data.length; i++) {

          let row = {
            // for database operation
            id: res.data[i].id,
            assortmentId: res.data[i].assortment.id,
            itemId: res.data[i].item.id,
            quantity: res.data[i].quantity,
            isFree: JSON.parse(res.data[i].isFree),
    
            // for UI table
            itemCode: res.data[i].item.itemCode,        
            itemName: res.data[i].item.name,        
            free: res.data[i].isFree === 'true'?'Free':''
          }
    
          setAssortmentItemTableRowData((pre)=>[...pre, row]);
        }
        
        
      } else {
        setAssortmentItemTableRowData([]);
      }
    } catch (error) {
      console.error("Assortment -> fetchAssortmentItemTableData: ", error);
    }
      
  };

  /*
  const handlePlanValueChangeButton = () => {
    setIsPlanValueInputDisabled(!isPlanValueInputDisabled);
    setPlanValueFormData((prevState) => ({
      ...prevState,
      valueOfInstallment: '',
      noOfInstallment: 12,
      startDate: '', //new Date().getFullYear() +'-'+(new Date().getMonth() + 1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
      endDate: "",     
    }));
  };
  */

  // basic input
  const [assortmentFormData, setAssortmentFormData] = useState({
    id: "",
    code: "",
    active: "Active",
    planId: 0,
    clusterId: 0,
    errors: {},
  });

  // assortment item input
  const [assortmentItemFormData, setAssortmentItemFormData] = useState({ 

    id: 0,   
    quantity: 0,
    isFree: false,

    categoryId: 0,
    itemId: 0,    
    errors: {},
  });


  // plan drop down box
  const[activePlanList, setActivePlanList] = useState([]);

  const fetchActivePlanList = async () => {
    try {
      const req = await getAllActivePlanList(loggedUser.token);
      setActivePlanList(req.data);
    } catch (error) {
      console.log(error);
    }
  }
  
  // fetch plan list to dropdown
  useEffect(() => {
    fetchActivePlanList();
  }, []);

  // cluster drop down box
  const [activeClusterList, setActiveClusterList] = useState([])

  const fetchActiveClusterList = async(planId) =>{
    try{
      const req = await getActiveClusterListByPlanId(loggedUser.token,planId)
      setActiveClusterList(req.data)
    }catch(error){
      console.error("Assortment -> fetchActiveClusterList: ", error);
    }
  }

  // category drop down box  
  const [activeCategoryList, setActiveCategoryList] = useState([]);

  const fetchActiveCategoryList = async () => {
    try {
      const req = await getActiveCategoryListOrderByAsc(loggedUser.token)      
      setActiveCategoryList(req.data);
    } catch (error) {
      console.log(error);
    }
  };

  // fetch category list to dropdown
  useEffect(() => {
    fetchActiveCategoryList();
  }, []);

  // item drop down box
  const [activeItemList, setActiveItemList] = useState([])

  const fetchActiveItemList = async(categoryId) =>{
    try{
      const req = await getActiveItemListByCategoryId(loggedUser.token,categoryId)     
      setActiveItemList([])           
      setActiveItemList(req.data)
    }catch(error){
      console.error("Assortment -> fetchActiveItemList: ", error);
    }
  }

  // set item info on pop over when change item drop down
  const [selectedItemInfo, setSelectedItemInfo] = useState({   
    itemCode: "",
    itemName: '',
    refName: "",
    manufacturer: "",
    imageUrl: ""
  });

  const setItemInfoOnPopOver = async(itemId) =>{
   
    try {
      const req = await getItemById(loggedUser.token, itemId)      
      setSelectedItemInfo({
        itemCode: req.data.itemCode,
        itemName: req.data.name,
        refName: req.data.refName,
        manufacturer: req.data.manufacturer,
        imageUrl: req.data.imageUrl
      })
    } catch (error) {
      console.error("Assortment -> setItemInfoOnPopOver: ", error);
    }
  }

   
  const handleAssortmentItemAddButton = () =>{
    setIsValidAssortmentItemInputs(true)

    if(isValidAssortmentItemInputs()){

      let row = {
        // for database operation
        id: 0,
        assortmentId: 0,
        itemId: assortmentItemFormData.itemId,
        quantity: assortmentItemFormData.quantity,
        isFree: assortmentItemFormData.isFree,

        // for UI table
        itemCode: selectedItemInfo.itemCode,        
        itemName: selectedItemInfo.itemName,        
        free: assortmentItemFormData.isFree?'Free':''
      }

      setAssortmentItemTableRowData((pre)=>[...pre, row]);

      // clear inputs
      setAssortmentItemFormData({
        quantity: 0,
        isFree: false,
        categoryId: 0,
        itemId: 0,    
        errors: {},
      });

      setActiveItemList([])
      setSelectedItemInfo({})
    }
  }


  const handledAssortmentItemTableRowDeleteButton = (param) =>{
    const deletedRowData = param.data;
    setAssortmentItemTableRowData((pre) => pre.filter((row)=> row !== deletedRowData))
  }


  const [isAssortmentItemInputDisabled, setIsAssortmentItemInputDisabled] = useState(false);

  const handleBasicInputChange = (e) => {
    const { name, value } = e.target;
    setAssortmentFormData((prevState) => ({ ...prevState, [name]: value }));

    if(name == 'planId'){
      fetchActiveClusterList(value)
      setAssortmentFormData((prevState) =>({...prevState,
        code: '',
        clusterId: 0,
      }))  
    }

    if(name == 'clusterId'){
      setAssortmentFormData((prevState) =>({...prevState,
        code: e.target.options[e.target.selectedIndex].text+"-"        
      }))        
    }
  };

  const handleAssortmentItemInputChange = (e) => {   

    if(e.target.name === 'isFree'){
      const { name, checked } = e.target;
      setAssortmentItemFormData((pre) => ({ ...pre, [name]: checked }));
    }else{
      const { name, value } = e.target;
      setAssortmentItemFormData((pre) => ({ ...pre, [name]: value }));
    }
    
    const { name, value } = e.target;

    if(name == 'categoryId'){      
      fetchActiveItemList(value)
      setAssortmentItemFormData((pre) =>({...pre,
        itemId: 0
      }))
      setSelectedItemInfo({
        code: '',
        refName: '',
        manufacturer: '',
        imageUrl: ''
      })
    }
    
    if(name == 'itemId'){
      setItemInfoOnPopOver(value)
    }
  };

  // for validation inputs
  // basic inputs
  const [isValidPlan, setIsValidPlan] = useState(true);
  const [isValidCluster, setIsValidCluster] = useState(true);
  const [isValidCode, setIsValidCode] = useState(true);

  const isValidBasicInputs = () => {
    const errors = {};

    if(assortmentFormData.planId == 0){
      setIsValidPlan(false)
      errors.plan = "Plan is Required";
    }

    if(assortmentFormData.clusterId == 0){
      setIsValidCluster(false)
      errors.plan = "Cluster is Required";
    }

    if (!assortmentFormData.code) {
      setIsValidCode(false);
      errors.code = "Code is Required";
    }    

    // return true if there are no errors
    return Object.keys(errors).length === 0;
  };


  // assortment item inputs
  const [isValidCategory, setIsValidCategory] = useState(true);
  const [isValidItem, setIsValidItem] = useState(true);
  const [isValidQuantity, setIsValidQuantity] = useState(true);

  const isValidAssortmentItemInputs = () => {
    const errors = {};

    if(assortmentItemFormData.categoryId == 0){
      setIsValidCategory(false)
      errors.category = "Category is Required";
    }

    if(assortmentItemFormData.itemId == 0){
      setIsValidItem(false)
      errors.item = "Item is Required";
    }

    if(assortmentItemFormData.quantity <= 0){
      setIsValidQuantity(false)
      errors.quantity = "Quantity not null";
    }

    // return true if there are no errors
    return Object.keys(errors).length === 0;
  };

  // change input validation state
  const setIsValidBasicInputs = (isValid) => {
    setIsValidPlan(isValid);
    setIsValidCluster(isValid);
    setIsValidCode(isValid)   
  };

  const setIsValidAssortmentItemInputs = (isValid) =>{
    setIsValidCategory(isValid);
    setIsValidItem(isValid);
    setIsValidQuantity(isValid);
  }



  // clear inputs
  const clearFormInputs = () => {
    setAssortmentFormData({
      id: "",
      code: "",
      active: "Active",
      planId: 0,
      clusterId: 0,
      errors: {},
    });

    setAssortmentItemFormData({
      quantity: 0,
      isFree: false,
      categoryId: 0,
      itemId: 0,    
      errors: {},
    });
  };

  // initialize inputs and state
  const initialize = () => {
    //basic
    clearFormInputs();
    setOperationMode("new");
    setIsValidBasicInputs(true);
    setIsValidAssortmentItemInputs(true)
    setAssortmentStatus("Active");

    // assortment items
    setAssortmentItemTableRowData([]);
    setIsAssortmentItemInputDisabled(false);
  };

  //++++ Item List Tab Pane  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //++++ Tab Layout  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [tab_1_Hide, setTab_1_Hide] = useState("");
  const [tab_2_Hide, setTab_2_Hide] = useState("hidden");

  const TAB_ACTIVE_CSS = "text-master_color-400 border-master_color-400";
  const [tab_1_active_css, setTab_1_active_css] = useState(TAB_ACTIVE_CSS);
  const [tab_2_active_css, setTab_2_active_css] = useState("");

  const toggleTab = (id) => {
    if (id === 1) {
      setTab_1_Hide("");
      setTab_2_Hide("hidden");

      setTab_1_active_css(TAB_ACTIVE_CSS);
      setTab_2_active_css("");
    }

    if (id === 2) {
      setTab_1_Hide("hidden");
      setTab_2_Hide("");

      setTab_1_active_css("");
      setTab_2_active_css(TAB_ACTIVE_CSS);
    }
  };
  /****** Right Pane - end *****************************************************************/

  return (
    <div className="flex h-full">
      <div id="left-pane" className="w-1/5 z-10">
        <div
          className={`bg-white absolute top-0 bottom-0 ${leftPaneStyle} border-solid border-r border-slate-300`}
        >
          <div className="grid grid-cols-2">
            <div className="col-start-1 mt-1.5 ml-2.5">
              <p className="text-master_color-400 font-semibold text-lg font-sans drop-shadow mb-1.5">
                Assortment List
              </p>
            </div>
            <div className="col-start-2 flex justify-end">
              <button
                onClick={handleExpandLeftPane}
                className="text-master_color-400 font-bold rounded mt-1 mr-1"
              >
                {leftPaneExpandedButtonIcon}
              </button>
            </div>
            <div className="col-span-2 p-0"></div>
          </div>
          <hr className="border-slate-300 border-0.5 ml-0.5 mr-0.5"></hr>

          <div className={`h-[calc(100%_-_2.5rem)] p-2`}>
            <div className="ag-theme-quartz h-full">
              <AgGridReact
                ref={assortmentTableRef}
                columnDefs={assortmentTableColumnDefs}
                defaultColDef={useMemo(() => ({ suppressMovable: true }), [])}
                rowData={assortmentTableRowData}
                rowSelection={"single"}
                onSelectionChanged={handledOnRowClick}
                pagination={!paginationlHide}
                paginationPageSize={10}
                paginationPageSizeSelector={[5, 10, 15]}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="right-pane" className="w-4/5 z-0">
        <div id="button-container" className="flex gap-1.5 pl-2">
          <div className="mt-1">
            <button
              type="button"
              onClick={handleNewButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaPlus size={16} />
              &nbsp;New
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={assortmentStatus === "Active" ? false : true}
              onClick={handleSaveButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaSave size={16} />
              &nbsp;Save
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={operationMode === "new" ? true : false}
              onClick={handleDeleteButton}
              className="px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaCircleXmark size={16} />
              &nbsp;Delete
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={operationMode === "new" ? true : false}
              onClick={handleActiveButton}
              className="w-28 px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              {assortmentStatus === "Active" ? (
                <FaSquare size={12} color="red" />
              ) : (
                <FaCircle size={12} color="green" />
              )}
              &nbsp;
              {assortmentStatus === "Active" ? "Deactivate" : "Reactivate"}
            </button>
          </div>
          <div className="mt-2.5 ml-6 flex w-auto">
            <Notification response={apiResponse} />
          </div>
        </div>

        <hr className="border-slate-300 border-0.5 mt-1"></hr>

        <div id="tab-container" className="h-[calc(100%_-_6.5rem)]">
          <div
            id="plan-info-tab-pane"
            className={`p-6 pt-1 ${tab_1_Hide}`}
            role="tabpanel"
          >
            <div className="grid grid-cols-3 gap-9">
              <div>
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Basic
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">Plan</div>
                  <div className="col-span-2">
                    <select                      
                      name="planId"                      
                      value={assortmentFormData.planId}
                      onChange={handleBasicInputChange}
                      disabled={assortmentStatus === "Active" ? false : true}
                      placeholder="Required"
                      className={`w-full text-sm pl-1 py-1 outline-none border focus:border-master_color-200 rounded
                            ${
                              isValidPlan
                                ? "border-slate-400"
                                : "border-red-600"
                            }
                            ${
                              assortmentStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            } 
                            `}
                    >
                      <option value="0" disabled>-- select plan --</option>
                      {activePlanList.map((plan) => (
                      <option key={plan.id} value={plan.id}>
                        {plan.code}
                      </option>
                    ))}      
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Cluster</div>
                  <div className="col-span-2">
                    <select                      
                      name="clusterId"
                      value={assortmentFormData.clusterId}
                      onChange={handleBasicInputChange}
                      disabled={assortmentStatus === "Active" ? false : true}
                      placeholder="Required"
                      className={`w-full text-sm pl-1 py-1 outline-none border focus:border-master_color-200 rounded
                            ${
                              isValidCluster
                                ? "border-slate-400"
                                : "border-red-600"
                            }
                            ${
                              assortmentStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            } 
                            `}
                    >
                      <option value="0" disabled>-- select cluster --</option>
                      {activeClusterList.map((cluster) => (
                      <option key={cluster.id} value={cluster.id}>
                        {cluster.code}
                      </option>
                    ))}      
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Assort. Code</div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="code"
                      placeholder="Assortment Code"
                      value={assortmentFormData.code}
                      onChange={handleBasicInputChange}
                      disabled={assortmentStatus === "Active" ? false : true}
                      className={`w-full text-sm   pl-2 py-1 outline-none border focus:border-master_color-200 rounded                            
                            ${
                              isValidCode
                                ? "border-slate-400"
                                : "border-red-600"
                            }
                            ${
                              assortmentStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            } 
                            `}
                    />
                  </div>
                </div>
              </div>
              <div></div>
              <div></div>

              <div>
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Add Items
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">Category</div>
                  <div className="col-span-2">
                    <select
                      name="categoryId"
                      value={assortmentItemFormData.categoryId}
                      onChange={handleAssortmentItemInputChange}
                      disabled={assortmentStatus === "Active" ? false : true}
                      className={`w-full pl-1 py-1 text-sm outline-none border focus:border-master_color-200 rounded 
                              ${
                                isValidCategory
                                  ? "border-slate-400"
                                  : "border-red-600"
                              }
                              ${
                                assortmentStatus === "Active"
                                  ? "bg-white"
                                  : "bg-gray-200"
                              }
                              `}
                    >
                      <option value="0" disabled>
                        -- select category --
                      </option>
                      {activeCategoryList.map((cat) => (
                        <option key={cat.id} value={cat.id}>
                          {cat.name}
                        </option>
                      ))}
                    </select>                   
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Item</div>
                  <div className="col-span-2">
                    <select
                      name="itemId"
                      value={assortmentItemFormData.itemId}
                      onChange={handleAssortmentItemInputChange}
                      disabled={assortmentStatus === "Active" ? false : true}
                      className={`w-full pl-1 py-1 text-sm outline-none border focus:border-master_color-200 rounded 
                              ${
                                isValidItem
                                  ? "border-slate-400"
                                  : "border-red-600"
                              }
                              ${
                                assortmentStatus === "Active"
                                  ? "bg-white"
                                  : "bg-gray-200"
                              }
                              `}
                    >
                      <option value="0" disabled>
                        -- select item --
                      </option>
                      {activeItemList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select> 
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-0">
                  <div className="pt-0.5 font-sans drop-shadow"></div>
                  <div className="col-span-2">

                    <div class="relative group inline-block">
                      <button class="text-master_color-300 text-sm ml-0.5 transition duration-300 ease-in-out transform">Item info</button>
                      <div class="opacity-0 invisible group-hover:opacity-100 group-hover:visible absolute w-max p-4 bg-white text-gray-800 border border-gray-300 rounded-lg shadow-lg z-10">
                        <div className="col-span-3 w-full h-44 border-2 rounded-md bg-slate-100 overflow-auto items-center justify-center">
                          <img       
                            src={selectedItemInfo.imageUrl}                                          
                          />
                        </div>
                        <div class="grid grid-cols-2 gap-1">
                          <div className="text-right mt-1">                            
                            <p className="mt-0.5"><span className="px-4 py-0.5 text-sm font-semibold text-white bg-sky-500 rounded-lg">Item Code</span></p>
                            <p className="mt-0.5"><span className="px-4 py-0.5 text-sm font-semibold text-white bg-sky-500 rounded-lg">Reference Name</span></p>
                            <p className="mt-0.5"><span className="px-4 py-0.5 text-sm font-semibold text-white bg-sky-500 rounded-lg">Manufacturer</span></p>                         
                          </div>
                          <div className="text-left mt-0.5">
                            <p className="mt-0.5"><span className="px-4 py-0.5 text-sm text-slate-500 font-bold">{selectedItemInfo.itemCode}</span></p>
                            <p className="mt-0.5"><span className="px-4 py-0.5 text-sm text-slate-500 font-semibold">{selectedItemInfo.refName}</span></p>  
                            <p className="mt-0.5"><span className="px-4 py-0.5 text-sm text-slate-500 font-semibold">{selectedItemInfo.manufacturer}</span></p>                              
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="grid grid-cols-3 mt-3">
                <div className="pt-0.5 font-sans drop-shadow">Quantity</div>
                  <div className="col-span-2">
                    <input
                      type="number"
                      name="quantity"
                      placeholder="Quantity"
                      value={assortmentItemFormData.quantity}
                      onChange={handleAssortmentItemInputChange}
                      disabled={assortmentStatus === "Active" ? false : true}
                      className={`w-full text-sm pl-2 py-1 outline-none border focus:border-master_color-200 rounded                            
                            ${
                              isValidQuantity
                                ? "border-slate-400"
                                : "border-red-600"
                            }
                            ${
                              assortmentStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            } 
                            `}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                <div className="pt-0.5 font-sans drop-shadow">Free</div>
                  <div className="col-span-2">
                    <input
                      type="checkbox"
                      name="isFree"                     
                      checked={assortmentItemFormData.isFree}
                      onChange={handleAssortmentItemInputChange}
                      disabled={assortmentStatus === "Active" ? false : true}
                      className={`h-5 w-5 mt-1 rounded-md 
                              ${
                                assortmentStatus === "Active"
                                  ? "bg-white"
                                  : "bg-gray-200"
                              } 
                            `}
                    />                    
                  </div>                  
                </div>
                <div className="grid grid-cols-3 mt-2">
                <div className="pt-0.5 font-sans drop-shadow"></div>
                  <div className="col-span-2">
                    <button
                      type="button"
                      disabled={assortmentStatus === "Active" ? false : true}
                      onClick={handleAssortmentItemAddButton}
                      className="px-6 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
                      >
                        Add&nbsp;&nbsp;
                        <FaArrowRight size={16} className="mt-0.5"/>                      
                    </button>
                  </div>                  
                </div>
                
              </div>
              <div className="col-span-2 row-span-2">
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Assortment Item List
                </div>
                <div className="ag-theme-quartz h-full pt-1 w-11/12">
                  <AgGridReact
                    ref={assortmentItemTableRef}
                    columnDefs={assortmentItemTableColumnDefs}
                    defaultColDef={useMemo(
                      () => ({ suppressMovable: true }),
                      []
                    )}
                    rowData={assortmentItemTableRowData}
                    rowSelection={"single"}
                    rowHeight={30}
                  />                   
                </div>
               
              </div>
            </div>
          </div>

          <div
            className={`p-4 pt-1 ${tab_2_Hide} h-full`}
            id="styled-dashboard"
            role="tabpanel"
          >
            Cluster List
          </div>
        </div>

        <div
          id="tab-button-container"
          className="mb-4 border-b border-gray-200"
        >
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="default-styled-tab"
            data-tabs-toggle="#default-styled-tab-content"
            data-tabs-active-classes="text-master_color-400 hover:text-master_color-400 border-master_color-400 "
            data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
            role="tablist"
          >
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 drop-shadow-sm ${tab_1_active_css}`}
                id="profile-styled-tab"
                data-tabs-target="#styled-profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={() => toggleTab(1)}
              >
                Assortment Info
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`hidden p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 ${tab_2_active_css}`}
                id="dashboard-styled-tab"
                data-tabs-target="#styled-dashboard"
                type="button"
                role="tab"
                aria-controls="dashboard"
                aria-selected="false"
                onClick={() => toggleTab(2)}
              >
                Extra
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
