import { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { Popover } from "react-tiny-popover";
import { getActiveCustomerList, getCustomerById } from "../../service/CustomerService";
import { AgGridReact } from "ag-grid-react";

function CustomerPopover({onSelectCustomer,isCustomerClear, setCusCode}) {

  const loggedUser = JSON.parse(
    atob(sessionStorage.getItem("x$sk72hyQwersgyhk"))
  );

  const[customerCode, setCustomerCode] = useState(''); 
  
  const setSelectedCustomer = (customer) =>{ 
    onSelectCustomer(customer)
  }

  const [isOpen, setIsOpen] = useState(false);
  
  // Customer List Table
  const customerTableRef = useRef();
  const [paginationlHide, setPaginationlHide] = useState(true);
  const [customerTableRowData, setCustomerTableRowData] = useState([]);

  const [customerTableColumnDefs, setCustomerTableColumnDefs] = useState([
    {
      colId: "id",
      valueGetter: (r) => r.data.id,
      //flex: 1,
      hide: true,
    },    
    {
      colId: "code",
      headerName: "Customer Code",
      valueGetter: (r) => r.data.code,
      filter: true,
      flex: 0.5,
    },
    {
      colId: "name",
      headerName: "Name",
      valueGetter: (r) => r.data.name,
      filter: true,
      //flex: 1,      
    },
    {
      colId: "route",
      headerName: "Route",
      valueGetter: (r) => r.data.route.name,
      filter: true,     
      //flex: 1,     
    },
    {
      colId: "phone1",
      headerName: "Phone 1",
      valueGetter: (r) => r.data.phone1,
      filter: true,
      flex: 0.4,     
    },    
  ]);

  const fetchCustomerTableData = async () => {
    try {
      const res = await getActiveCustomerList(loggedUser.token);

      if (res !== undefined) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].index = i + 1;
        }
        setCustomerTableRowData(res.data);
      } else {
        setCustomerTableRowData([]);
      }
    } catch (error) {
      console.error("CustomerPopover -> fetchCustomerTableData: ", error);
    }
  };  
  
  
  useEffect(() => {
    fetchCustomerTableData();    
  }, []);

  useEffect(() => {
    setCustomerCode(setCusCode);    
  }, [setCusCode]);

  
  const handledOnRowClick = useCallback( async() => {
    const selectedRows = customerTableRef.current.api.getSelectedRows();

    if (selectedRows.length === 1) {      
      setCustomerCode(selectedRows[0].code)      
      setSelectedCustomer({
        id: selectedRows[0].id,
        name: selectedRows[0].name,
        phone1: selectedRows[0].phone1,
        phone2: selectedRows[0].phone2,
        route: selectedRows[0].route.name
      });
    }
  }, []);

  const handledOnRowDoubleClick = useCallback( async() => {
    const selectedRows = customerTableRef.current.api.getSelectedRows();
    
    if (selectedRows.length === 1) {      
      setCustomerCode(selectedRows[0].code)      
      setSelectedCustomer({
        id: selectedRows[0].id,
        name: selectedRows[0].name,
        route: selectedRows[0].route.name
      });
    }
    setIsOpen(false)
  }, []);

  

  return ( 
    <div>
      <Popover
        isOpen={isOpen}
        positions={["right"]} 
        padding={2}
        onClickOutside={() => setIsOpen(false)}
        content={
           
             <div className="ag-theme-quartz h-80 w-[700px] absolute -mt-4 left-0.5">
                <AgGridReact
                  ref={customerTableRef}
                  columnDefs={customerTableColumnDefs}
                  rowData={customerTableRowData} 
                  headerHeight={35}                  
                  rowHeight={28}
                  pagination={!paginationlHide}
                  paginationPageSize={10}
                  paginationPageSizeSelector={[5, 10, 15]}
                  defaultColDef={useMemo(() => ({ suppressMovable: true }), [])}
                  rowSelection={"single"}
                  onSelectionChanged={handledOnRowClick} 
                  onRowDoubleClicked={handledOnRowDoubleClick}                            
                />
              </div>
         
        }
      >
        
        <input  
          type="text"                      
          name="customerId"
          placeholder="Required"
          readOnly={true}
          onClick={() => setIsOpen(true)}     
          className={`w-full text-sm font-semibold pl-2 py-1 outline-none bg-white border focus:border-master_color-200 border-slate-400 rounded`}
          value={isCustomerClear ? '': customerCode }
          
        >                                          
        </input>
        
      </Popover>
    </div>
   );
}

export default CustomerPopover;