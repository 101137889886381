import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL+'/sample';


export const saveSample = async(token,sample) => {
  let response = null   
  try{
      response = await axios.post(API_BASE_URL,sample,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('SampleService -> saveSample: '+error);
  }finally {
      return response.data;
  }
}

export const updateSample = async(token,sample) => {
  let response = null   
  try{
      response = await axios.put(API_BASE_URL+'/'+sample.id,sample,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('SampleService -> updateSample: '+error);
  }finally {
      return response.data;
  }
}

export const deleteSample = async(token,sampleId) => {
  let response = null   
  try{
      response = await axios.delete(API_BASE_URL+'/'+sampleId,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('SampleService -> deleteSample: '+error);
  }finally {
      return response.data;
  }
} 

export const getSampleListWithRecentTrackingLog = async(token) => {
  let sampleList = null
  try{
    sampleList = await axios.get(API_BASE_URL+'/recentTrackingLog',{ 
          headers: {                                                                                                   
                  'Authorization': `Bearer ${token}`                                                   
                  }            
          }); 
          
      return sampleList.data;
  }catch(error){             
      console.log('SampleService -> getSampleList: '+error);
  }
}

export const generateSampleCode = async (token,customerId) => {
  let res = null;
  try {
    res = await axios.get(API_BASE_URL + "/generate/sampleCode?customerId="+customerId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });  
    
    return res.data;
  } catch (error) {
    console.log("SampleService -> generateSampleCode: " + error);
  }
};