import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL+'/employee';

export const getActiveSalesReptList = async(token) => {
  let roleId = 1 
  let list = null
  try{
    list = await axios.get(API_BASE_URL+'?roleId='+roleId+'&status=Active',{ 
          headers: {                                                                                                   
                  'Authorization': `Bearer ${token}`                                                   
                  }            
          });            
      return list.data;
  }catch(error){             
      console.log('EmployeeService -> getActiveSalesReptList: '+error);
  }
}

export const getActiveDrivertList = async(token) => {
  let roleId = 2 
  let list = null
  try{
    list = await axios.get(API_BASE_URL+'?roleId='+roleId+'&status=Active',{ 
          headers: {                                                                                                   
                  'Authorization': `Bearer ${token}`                                                   
                  }            
          });            
      return list.data;
  }catch(error){             
      console.log('EmployeeService -> getActiveDrivertList: '+error);
  }
}

export const getAllEmployeeList = async(token) => {  
  let list = null
  try{
    list = await axios.get(API_BASE_URL+'?&status=All',{ 
          headers: {                                                                                                   
                  'Authorization': `Bearer ${token}`                                                   
                  }            
          });            
      return list.data;
  }catch(error){             
      console.log('EmployeeService -> getEmployeeList: '+error);
  }
}

export const saveEmployee = async (token, employee) => {
  let response = null;
  try {
    response = await axios.post(API_BASE_URL, employee, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("EmployeeService -> saveCustomer: " + error);
  } finally {
    return response.data;
  }
};

export const updateEmployee = async (token, employee) => {
  let response = null;
  try {
    response = await axios.put(API_BASE_URL + "/" + employee.id, employee, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("EmployeeService -> updateEmployee: " + error);
  } finally {
    return response.data;
  }
};

export const deleteEmployee = async (token, empId) => {
  let response = null;
  try {
    response = await axios.delete(API_BASE_URL + "/" + empId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("EmployeeService -> deleteEmployee: " + error);
  } finally {
    return response.data;
  }
};

export const deActivateEmployee = async (token, empId) => {
  let response = null;
  try {
    response = await axios.put(API_BASE_URL +"/"+ empId+"/deactivate" , "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("EmployeeService -> deActivateEmployee: " + error);
  } finally {
    return response.data;
  }
};

export const activateEmployee = async (token, empId) => {
  let response = null;
  try {
    response = await axios.put(API_BASE_URL + "/"+ empId+"/activate", "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("EmployeeService -> activateEmployee: " + error);
  } finally {
    return response.data;
  }
};

export const generateEmployeeCode = async (token,roleId) => {
  let res = null;
  try {
    res = await axios.get(API_BASE_URL + "/generate/employeeCode?roleId="+roleId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });    
    return res.data;
  } catch (error) {
    console.log("EmployeeService -> generateEmployeeCode: " + error);
  }
};
