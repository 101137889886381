import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL+'/sampleTrackingLog';


export const saveSampleTrackingLog = async(token,sampleTrackingLog) => {
  let response = null   
  try{
      response = await axios.post(API_BASE_URL,sampleTrackingLog,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('SampleTrackingLogService -> saveSampleTrackingLog: '+error);
  }finally {
      return response.data;
  }
}

export const getSampleTrackingLogListBySampleId = async(token,sampleId) => {
    let list = null
    try{
        list = await axios.get(API_BASE_URL+"?sampleId="+sampleId,{ 
            headers: {                                                                                                   
                    'Authorization': `Bearer ${token}`                                                   
                    }            
            });            
        return list.data;
    }catch(error){             
        console.log('SampleTrackingLogService -> getSampleTrackingLogListBySampleId: '+error);
    }
  }
  