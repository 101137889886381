import { useRef, useState, useMemo, useCallback, useEffect } from "react";

import {
  FaCaretLeft,
  FaCaretRight,
  FaPlus,
  FaCircleXmark,
  FaSquare,
  FaCircle,
} from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import 'ag-grid-community/styles/ag-theme-quartz.css'; 
import Notification from "../component/utility/Notification";
import { deleteSample, generateSampleCode, getSampleListWithRecentTrackingLog, saveSample, updateSample } from "../service/SampleService";
import { Popover } from "react-tiny-popover";
import CustomerPopover from "../component/popover/CustomerPopover";
import AssortmentPopover from "../component/popover/AssortmentPopover";
import { getActiveDrivertList, getActiveSalesReptList } from "../service/EmployeeService";
import { getSampleTrackingLogListBySampleId, saveSampleTrackingLog } from "../service/SampleTrackingLogService";



function Sample() {

  const loggedUser = JSON.parse(
    atob(sessionStorage.getItem("x$sk72hyQwersgyhk"))
  );

  /****** Left Pane - start ***************************************************************/

  const [leftPaneStyle, setLeftPaneStyle] = useState("w-1/5");
  const [isLeftPaneExpanded, setIsLeftPaneExpanded] = useState(false);
  const [leftPaneExpandedButtonIcon, setLeftPaneExpandedButtonIcon] = useState(
    <FaCaretRight size={36} />
  );

  //++++ Sample List DataTable  ++++++++++++++++++++++++++++++++
  const sampleTableRef = useRef();
  const [sampleTableRowData, setSampleTableRowData] = useState([]);
  const [paginationlHide, setPaginationlHide] = useState(true);
  

  const [sampleTableColumnDefs, setSampleTableColumnDefs] = useState([
    { 
      colId: "id", 
      valueGetter: (r) => r.data.id, 
      flex: 1,
      hide: true 
    },
    {
      colId: "index",
      headerName: "#",
      valueGetter: (r) => r.data.index,
      flex: 0.2,
      hide: true,
    },
    {
      colId: "code",
      headerName: "Sample Code",
      valueGetter: (r) => r.data.sampleCode,
      filter: true,
      flex: 0.9,      
    },    
    {
      colId: "customer",
      headerName: "Customer",
      valueGetter: (r) => r.data.customer.name,
      filter:true,
      flex: 1,
      hide: true,
    },
    {
      colId: "route",
      headerName: "Route",
      valueGetter: (r) => r.data.customer.route.name,
      filter:true,
      flex: 1,
      hide: true,
    },    
    {
      colId: "assortment",
      headerName: "Assortment",
      valueGetter: (r) => r.data.assortment.code,
      flex: 0.8,
      hide: true,
      filter: true
    },    
    {
      colId: "origin",
      headerName: "Origin",
      valueGetter: (r) => r.data.origin,
      flex: 0.6,
      hide: true,
      filter: true
    },    
    {
      colId: "salesRep",
      headerName: "Sales Rep",
      valueGetter: (r) => r.data.salesRep.fullName,
      flex: 1,
      hide: true,
      filter: true
    },
    {
      colId: "driver",
      headerName: "Driver",
      valueGetter: (r) => r.data.driver.fullName,
      flex: 1,
      hide: true,
      filter: true
    },
    {
      colId: "date",
      headerName: "Issued Date",
      valueGetter: (r) => r.data.issueDate,
      flex: 0.8,
      hide: true,
      filter: true
    },    
    {
      colId: "status",
      headerName: "Status",
      valueGetter: (r) => r.data.recentSampleTrackingLog.sampleStatus,
      flex: 0.7,
      hide: true,
      filter: true
    },
    {
      colId: "lastUpdated",
      headerName: "Last Updated",
      valueGetter: (r) => r.data.recentSampleTrackingLog.updatedAt,
      flex: 0.8,
      hide: true,
      filter: true
    },
    
  ]);

  // functions
  const handleExpandLeftPane = () => {
    if (!isLeftPaneExpanded) setIsLeftPaneExpanded(true);
    else setIsLeftPaneExpanded(false);

    if (isLeftPaneExpanded) {
      setLeftPaneStyle("w-1/5");
      setLeftPaneExpandedButtonIcon(<FaCaretRight size={36} />);
      sampleTableRef.current.api.setColumnsVisible(
        ["customer","route","assortment","origin","date","status","salesRep","driver","lastUpdated"],
        false
      );
      setPaginationlHide(true);
    } else {
      setLeftPaneStyle("w-screen");
      setLeftPaneExpandedButtonIcon(<FaCaretLeft size={36} />);
      sampleTableRef.current.api.setColumnsVisible(
        ["customer","route","assortment","origin","date","status","salesRep","driver","lastUpdated"],
        true
      );
      setPaginationlHide(false);
    }
  };

  const fetchSampleTableData = async () => {
    try {
      const res = await getSampleListWithRecentTrackingLog(loggedUser.token);

      for (let i = 0; i < res.data.length; i++) {
        res.data[i].index = i + 1;
      }
      
      setSampleTableRowData(res.data);
    } catch (error) {
      console.error("Sample -> fetchSampleTableData: ", error);
    }
  };

  useEffect(() => {
    fetchSampleTableData();    
  }, []);


  const handledOnRowClick = useCallback(() => {
    const selectedRows = sampleTableRef.current.api.getSelectedRows();

    if (selectedRows.length === 1) {

      setSampleFormData((prevState) => ({
        ...prevState,
        id: selectedRows[0].id,
        customerId: selectedRows[0].customer.id,
        customerCode: selectedRows[0].customer.code,
        customerName: selectedRows[0].customer.name,
        phone1: selectedRows[0].customer.phone1,
        phone2: selectedRows[0].customer.phone2?selectedRows[0].customer.phone2:'',
        route: selectedRows[0].customer.route.name,

        sampleCode: selectedRows[0].sampleCode ,
        assortmentId: selectedRows[0].assortment.id,
        assortmentCode:selectedRows[0].assortment.code,
        planValueId: selectedRows[0].planValue.id,
        planValue: 'Rs.'+(selectedRows[0].planValue.valueOfInstallment * selectedRows[0].planValue.noOfInstallment),
        origin: selectedRows[0].origin,

        salesRepId: selectedRows[0].salesRep.id,
        driverId: selectedRows[0].driver.id,
        issueDate: selectedRows[0].issueDate,
        
      }));     
      
      

      setOperationMode("update");
      setApiResponse({
        status: "",
        message: "Finished loading : " + selectedRows[0].sampleCode,
      });
      setSampleStatus('Active');
      setIsValidInputs(true);

      // fetch sample history      
      fetchSampleStatusTableData(selectedRows[0].id)
      setSampleTrackingLogFormData({
        id: '',
        status: 0,
        remark: '',
        updatedAt: ''
      })

      //popovers
      setIsCustomerClear(false)
      setIsAssortmentClear(false)

    }
  }, []);

  /****** Left Pane - end ***************************************************************/

  /****** Right Pane - start ***************************************************************/
  //++++ Button Container  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [operationMode, setOperationMode] = useState("new"); // "new", "save", or "update"
  const [apiResponse, setApiResponse] = useState({
    //status: true, data: null, message: 'Already added : Fabric'
    status: "",
    message: "",
  });
  const [sampleStatus, setSampleStatus] = useState("Active");

  const handleNewButton = () => {
    initialize();   
    setApiResponse({ status: "", message: "Add new sample" });    
  };

  const handleSaveButton = async (e) => {
    e.preventDefault();
    setIsValidInputs(true);

    let sample = {
      id: sampleFormData.id,
      sampleCode: sampleFormData.sampleCode,
      origin: sampleFormData.origin,
      salesRepId: sampleFormData.salesRepId,
      driverId: sampleFormData.driverId,
      issueDate: sampleFormData.issueDate,
      customerId: sampleFormData.customerId,
      assortmentId: sampleFormData.assortmentId,
      planValueId: sampleFormData.planValueId,
    };

    let resSample,resSampleTrackingLog = null;

    if (isValidInputs()) {
      if (operationMode === "new") {        
        resSample =  await saveSample(loggedUser.token, sample);

        if(resSample.status){
          let sampleTrackingLog = {
            sampleStatus: 'DELIVERED',
            remark: '',
            updatedAt: resSample.data.issueDate,
            sampleId: resSample.data.id
          }

          resSampleTrackingLog = await saveSampleTrackingLog(loggedUser.token,sampleTrackingLog)
        }
        setApiResponse({ status: resSample.status, message: resSample.message });
        if (resSample.status && resSampleTrackingLog.status) {
          initialize();
        }
      } else if (operationMode === "update") {

        resSample =  await updateSample(loggedUser.token, sample);

        if(resSample.status && sampleTrackingLogFormData.updatedAt){
          let sampleTrackingLog = {
            sampleStatus: sampleTrackingLogFormData.status,
            remark: sampleTrackingLogFormData.remark,
            updatedAt: sampleTrackingLogFormData.updatedAt,
            sampleId: resSample.data.id
          }

          resSampleTrackingLog = await saveSampleTrackingLog(loggedUser.token,sampleTrackingLog)
        }
        
        setApiResponse({ status: resSample.status, message: resSample.message });
        if (resSample.status) {
          initialize();
        }
      } else {
        setApiResponse({ status: false, message: "Error" });
      }
    } else {
      setApiResponse({
        status: false,
        message: "Error saving : Invalid inputs",
      });
    }

    fetchSampleTableData();
  };

  const handleDeleteButton = async (e) => {
    e.preventDefault();
    let res = null;
    if (sampleFormData.sampleCode) {
      //res = await deleteSample(loggedUser.token, sampleFormData.id);
      //setApiResponse({ status: res.status, message: res.message });
      if (false) {
        clearFormInputs();
      } else {
        setApiResponse({ status: false, message: "Error deleting..." });
      }
    }
    
    fetchSampleTableData();
  };

  

  //++++ Sample info Tab Pane  +++++++++++++++++++++++++++++++++++++++++++++++++++++
  // input values
  const [sampleFormData, setSampleFormData] = useState({
    id: "",
    customerId: 0,
    customerName: "",
    customerCode:'',
    phone1:'',
    phone2:'',
    route: "",

    sampleCode: "",
    assortmentId: 0,
    assortmentCode:"",
    planValueId: 0,
    planValue:'',
    origin: 0,

    salesRepId: 0,
    driverId: 0,
    issueDate: '',

    errors: {},
  });

  const [sampleTrackingLogFormData, setSampleTrackingLogFormData] = useState({
    id: '',
    status: 0,
    remark: '',
    updatedAt: ''
  })

  // sales rep drop down box
  const [activeSalesRepList, setActiveSalesRepList] = useState([])

  const fetchActiveSalesRepList = async() => {
    try {
          const req = await getActiveSalesReptList(loggedUser.token);
          setActiveSalesRepList(req.data);
        } catch (error) {
          console.log(error);
        }
  }

  // driver drop down box
  const [activeDriverList, setActiveDriverList] = useState([])

  const fetchActiveDriverList = async() => {
    try {
          const req = await getActiveDrivertList(loggedUser.token);
          setActiveDriverList(req.data);
        } catch (error) {
          console.log(error);
        }
  }

  useEffect(() => {    
    fetchActiveSalesRepList();
    fetchActiveDriverList();
  }, []);

  // sample status drop down box
  const sampleStatusList =[
    {id: 1 , name: 'PENDING'},
    {id: 2 , name: 'CONFIRMED'},    
    {id: 3 , name: 'REJECTED'},
  ]

  // sample status history DataTable
  const sampleStatusTableRef = useRef();
  const [sampleStatusTableRowData, setSampleStatusTableRowData] = useState([]);
  const [sampleStatusTableColumnDefs, setSampleStatusTableColumnDefs] = useState([ 
    { 
      colId: "id", 
      valueGetter: (r) => r.data.id, 
      flex: 1,
      hide: true 
    },             
    {
      colId: "status",
      headerName: "Status",
      valueGetter: (r) => r.data.sampleStatus, 
      cellStyle: {fontSize: '13.5px'},    
      flex: 0.6,     
    },
    {
      colId: "remark",
      headerName: "Remark",
      valueGetter: (r) => r.data.remark,
      flex: 1
    },
    {
      colId: "date",
      headerName: "Updated",
      valueGetter: (r) => r.data.updatedAt,      
      flex: 0.5,
    },   
    {
      colId: "period",
      headerName: "Duration",
      valueGetter: (r) => [new Date(r.data.updatedAt) - new Date(r.data.sample.issueDate)]/(1000*60*60*24) != 0 ? [new Date(r.data.updatedAt) - new Date(r.data.sample.issueDate)]/(1000*60*60*24):'',
      cellStyle: { textAlign: 'center',fontSize: '14px'},        
      flex: 0.5,
    },      
    
  ]);


  const[totalDuration , setTotalDuration] = useState();



  const fetchSampleStatusTableData = async (sampleId) => {
      
      try {
        setSampleStatusTableRowData([]);
        const res = await getSampleTrackingLogListBySampleId(loggedUser.token,sampleId);
        
        if (res !== undefined) {         
          setSampleStatusTableRowData(res.data)
          
          let lastIndex = res.data.length-1;
          if(['DELIVERED','PENDING'].includes(res.data[lastIndex].sampleStatus)){
            setTotalDuration((new Date() - new Date(res.data[0].updatedAt))/ (1000 * 60 * 60 * 24))
          } else{
            setTotalDuration(0)
          }
          
        } else {
          setSampleStatusTableRowData([]);
        }
      } catch (error) {
        console.error("Sample -> fetchSampleStatusTableData: ", error);
      }
        
    };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSampleFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSampleTrackingLogInputChange = (e) => {
    const {name, value } = e.target;
    setSampleTrackingLogFormData((preState) => ({...preState, [name]: value}))
  }

  // validation 
  const [isValidCustomerName, setIsValidCustomerName] = useState(true);
  const [isValidPlanValue, setIsValidPlanValue] = useState(true);
  const [isValidSalesRep, setIsValidSalesRep] = useState(true)
  const [isValidDriver, setIsValidDriver] = useState(true)
  const [isValidIssuedDate, setIsValidIssuedDate] = useState(true)

  const [isValidStatus, setIsValidStatus] = useState(true) 
  const [isValidUpdatedAt, setIsValidUpdatedAt] = useState(true)


  const isValidInputs = () => {
    const errors = {};

    if (sampleFormData.customerName === "" || sampleFormData.customerName === 0) {
      setIsValidCustomerName(false);
      errors.customerName = "Customer name is Required";
    } 

    if (sampleFormData.planValue === "" || sampleFormData.planValue === 0) {
      setIsValidPlanValue(false);
      errors.planValue = "Plan value is Required";
    } 
    
    if(!sampleFormData.salesRepId){
      setIsValidSalesRep(false)
      errors.salesRepId = "Sales Rep is Required"
    }

    if(!sampleFormData.driverId){
      setIsValidDriver(false)
      errors.driverId = "Driver is Required"
    }

    if(!sampleFormData.issueDate){
      setIsValidIssuedDate(false)
      errors.issueData = "Isuued Date is Required"
    }

    if(operationMode === 'update' && sampleTrackingLogFormData.status){

      if(!sampleTrackingLogFormData.status){
        setIsValidStatus(false)
        errors.status = 'Status is Required'
      }
  
      if(!sampleTrackingLogFormData.updatedAt){
        setIsValidUpdatedAt(false)
        errors.status = 'Update date is Required'
      }

    }    
    
    return Object.keys(errors).length === 0;
  };


  // change input validation state
  const setIsValidInputs = (isValid) => {
    setIsValidCustomerName(isValid); 
    setIsValidPlanValue(isValid);
    setIsValidSalesRep(isValid)
    setIsValidDriver(isValid)
    setIsValidIssuedDate(isValid) 
    
    setIsValidStatus(isValid)
    setIsValidUpdatedAt(isValid)
  };

  // clear inputs
  const clearFormInputs = () => {
    setSampleFormData({
      id: "",
      customerId: 0,
      customerName: "",
      customerCode: "",
      phone1:'',
      phone2:'',
      route: "",

      sampleCode: "",
      assortmentId: 0,
      assortmentCode:"",
      planValueId: 0,
      planValue:'',
      origin: 0,

      salesRepId: 0,
      driverId: 0,
      issueDate: '',
        
      errors: {},
    });

    setSampleTrackingLogFormData({
      id: '',
      status: 0,
      remark: '',
      updatedAt: ''
    })
  };

  // initialize inputs and state
  const initialize = () => {
    clearFormInputs();
    setOperationMode("new");
    setIsValidInputs(true);
    setSampleStatus("Active");

    //clear popovers
    setIsCustomerClear(true)
    setIsAssortmentClear(true)
  };

  

  //++++ Tab Layout  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [tab_1_Hide, setTab_1_Hide] = useState("");
  const [tab_2_Hide, setTab_2_Hide] = useState("hidden");

  const TAB_ACTIVE_CSS = "text-master_color-400 border-master_color-400";
  const [tab_1_active_css, setTab_1_active_css] = useState(TAB_ACTIVE_CSS);
  const [tab_2_active_css, setTab_2_active_css] = useState("");

  const toggleTab = (id) => {
    if (id === 1) {
      setTab_1_Hide("");
      setTab_2_Hide("hidden");

      setTab_1_active_css(TAB_ACTIVE_CSS);
      setTab_2_active_css("");
    }

    if (id === 2) {
      setTab_1_Hide("hidden");
      setTab_2_Hide("");

      setTab_1_active_css("");
      setTab_2_active_css(TAB_ACTIVE_CSS);
    }
  };


 //++++ Popovers  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [isCustomerClear, setIsCustomerClear] = useState(false);
  const [isAssortmentClear, setIsAssortmentClear] = useState(false);

  const handleSelectCustomer = async (customer) =>{

    try {
      let res = await generateSampleCode(loggedUser.token,customer.id)
     
      if(res.status){
        let newSampleCode = res.data;       
        setSampleFormData((prevState) =>({
          ...prevState,      
          customerId: customer.id,
          customerName: customer.name,
          phone1: customer.phone1,
          phone2: customer.phone2 ? customer.phone2:'',
          route: customer.route,
          sampleCode: newSampleCode,
        }))

      }else{
        setApiResponse({ status: false, message: "Server Error" });
      }
    } catch (error) {
      console.log(error);
    } 

    setIsCustomerClear(false)         
  }

  const handleSelectAssortment = async (assortment) =>{
    
    setSampleFormData((prevState) =>({
      ...prevState,      
      assortmentId: assortment.id,
      planValueId: assortment.planValueId,
      planValue: assortment.value
    }))    

    setIsAssortmentClear(false)         
  }

  

  /****** Right Pane - end *****************************************************************/ 
  return (
    <div className="flex h-full">
      <div id="left-pane" className="w-1/5 z-10">
        <div
          className={`bg-white absolute top-0 bottom-0 ${leftPaneStyle} border-solid border-r border-slate-300`}
        >
          <div className="grid grid-cols-2">
            <div className="col-start-1 mt-1.5 ml-2.5">
              <p className="text-master_color-400 font-semibold text-lg font-sans drop-shadow mb-1.5">
                Sample List
              </p>
            </div>
            <div className="col-start-2 flex justify-end">
              <button
                onClick={handleExpandLeftPane}
                className="text-master_color-400 font-bold rounded mt-1 mr-1"
              >
                {leftPaneExpandedButtonIcon}
              </button>
            </div>
            <div className="col-span-2 p-0"></div>
          </div>
          <hr className="border-slate-300 border-0.5 ml-0.5 mr-0.5"></hr>

          <div className={`h-[calc(100%_-_2.5rem)] p-2`}>
            <div className="ag-theme-quartz h-full">
              <AgGridReact
                ref={sampleTableRef}
                columnDefs={sampleTableColumnDefs}
                defaultColDef={useMemo(() => ({ suppressMovable: true }), [])}
                rowData={sampleTableRowData}
                rowSelection={"single"}
                onSelectionChanged={handledOnRowClick}
                pagination={!paginationlHide}
                paginationPageSize={10}
                paginationPageSizeSelector={[5, 10, 15]}                               
              />
            </div>
          </div>
        </div>
      </div>

      <div id="right-pane" className="w-4/5 z-0">
        <div id="button-container" className="flex gap-1.5 pl-2">
          <div className="mt-1">
            <button
              type="button"
              onClick={handleNewButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaPlus size={16} />
              &nbsp;New
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={sampleStatus === "Active" ? false : true}
              onClick={handleSaveButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaSave size={16} />
              &nbsp;Save
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={operationMode === "new" ? true : false}
              onClick={handleDeleteButton}
              className="px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaCircleXmark size={16} />
              &nbsp;Delete
            </button>
          </div>
          <div className="mt-1 hidden">
            
          </div>
          <div className="mt-2.5 ml-6 flex w-auto">
            <Notification response={apiResponse} />
          </div>
        </div>

        <hr className="border-slate-300 border-0.5 mt-1"></hr>

        <div id="tab-container" className="h-[calc(100%_-_6.5rem)]">
          <div
            id="sample-info-tab-pane"
            className={`p-6 pt-1 ${tab_1_Hide}`}
            role="tabpanel"
          >
            <div className="grid grid-cols-3 gap-12 ">
              <div>
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Customer Info
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">Customer</div>                
                  <div className="col-span-2">                                       
                    <CustomerPopover 
                      onSelectCustomer={handleSelectCustomer} 
                      isCustomerClear={isCustomerClear} 
                      setCusCode={sampleFormData.customerCode}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Customer Name</div>
                  <div className="col-span-2">
                    <input  
                        type="text"                      
                        name="customerName"                       
                        readOnly={true}
                        disabled={true}                            
                        className={`w-full text-sm pl-2 py-1 outline-none bg-gray-100 border focus:border-master_color-200 rounded
                          ${isValidCustomerName? 'border-slate-400':'border-red-600'}
                          `}
                        value={sampleFormData.customerName}
                    >                                          
                    </input>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Contact</div>
                  <div className="col-span-2">
                    <input  
                        type="text"                      
                        name="customerName"                       
                        readOnly={true}
                        disabled={true}                            
                        className={`w-full text-sm pl-2 py-1 outline-none bg-gray-100 border focus:border-master_color-200 rounded
                          ${isValidCustomerName? 'border-slate-400':'border-red-600'}
                          `}
                        value={sampleFormData.phone1+`${sampleFormData.phone2? ' / ': '  '}`+sampleFormData.phone2}
                    >                                          
                    </input>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Route</div>
                  <div className="col-span-2">
                    <input  
                        type="text"                      
                        name="route"                       
                        readOnly={true}
                        disabled={true}                            
                        className={`w-full text-sm pl-2 py-1 outline-none bg-gray-100 border border-slate-400 focus:border-master_color-200 rounded`}
                        value={sampleFormData.route}
                      >                                          
                    </input>
                  </div>
                </div>                
              </div>

              <div>
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Sample Info
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">Sample Code</div>
                  <div className="col-span-2">
                    <input  
                      type="text"                      
                      name="sampleCode"                       
                      readOnly={true}
                      disabled={true}                            
                      className={`w-full text-sm font-bold pl-2 py-1 outline-none bg-gray-100 border border-slate-400 focus:border-master_color-200 rounded`}
                      value={sampleFormData.sampleCode}
                      >                                          
                    </input>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Assortment</div>
                  <div className="col-span-2">                                                         
                    <AssortmentPopover 
                      onSelectAssortment={handleSelectAssortment} 
                      isAssortmentClear={isAssortmentClear}
                      setAssortCode={sampleFormData.assortmentCode}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Value</div>
                  <div className="col-span-2">
                    <input  
                      type="text"                      
                      name="planValue"                       
                      readOnly={true}
                      disabled={true}                            
                      className={`w-full text-sm pl-2 py-1 outline-none bg-gray-100 border focus:border-master_color-200 rounded
                        ${isValidPlanValue? 'border-slate-400':'border-red-600'}
                        `}
                      value={sampleFormData.planValue}
                      >                                          
                    </input>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Origin</div>
                  <div className="col-span-2">
                    <select
                      name="origin"
                      value={sampleFormData.origin}
                      onChange={handleInputChange}                      
                      className={`w-full pl-1 py-1 text-sm outline-none bg-white border border-slate-400 focus:border-master_color-200 rounded`}                              
                    >
                      <option value="0" disabled={true}>-- select origin --</option>
                      <option value="STORE">STORE</option>     
                      <option value="ROUTE">ROUTE</option>
                    </select> 
                  </div>
                </div>
              </div>

              <div>
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Delivery Info
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">Sales Rep</div>                
                  <div className="col-span-2">                                       
                    <select
                      name="salesRepId"
                      value={sampleFormData.salesRepId}
                      onChange={handleInputChange}                                
                      className={`w-full text-sm pl-2 py-1 outline-none bg-white border focus:border-master_color-200 rounded
                        ${isValidSalesRep? 'border-slate-400':'border-red-600'}
                        `}
                    >
                      <option value="0" disabled={true}>
                        -- select sales rep --
                      </option>
                      {activeSalesRepList.map((rep) => (
                        <option key={rep.id} value={rep.id}>                          
                            {rep.employeeCode} &nbsp;&nbsp;&nbsp; {rep.fullName}                  
                        </option>
                      ))}
                    </select>      
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Driver</div>
                  <div className="col-span-2">
                  <select
                      name="driverId"
                      value={sampleFormData.driverId}
                      onChange={handleInputChange}                      
                      className={`w-full text-sm pl-2 py-1 outline-none bg-white border focus:border-master_color-200 rounded
                        ${isValidDriver? 'border-slate-400':'border-red-600'}
                        `}
                    >
                      <option value="0" disabled> 
                        -- select driver --
                      </option>
                      {activeDriverList.map((driver) => (
                        <option key={driver.id} value={driver.id}>                          
                            {driver.employeeCode} &nbsp;&nbsp;&nbsp; {driver.fullName}                  
                        </option>
                      ))}
                    </select> 
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Issued Date</div>
                  <div className="col-span-2">
                    <input  
                      type="date"                      
                      name="issueDate"
                      value={sampleFormData.issueDate}
                      onChange={handleInputChange}                                          
                      className={`w-full text-sm pl-2 py-1 outline-none bg-white border focus:border-master_color-200 rounded
                        ${isValidIssuedDate? 'border-slate-400':'border-red-600'}
                        `}                      
                    >                                          
                    </input>
                  </div>
                </div>                
              </div>

              <div className={operationMode === 'new' ? 'hidden':''}>
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                 Update Status
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">Status</div>                
                  <div className="col-span-2">                                       
                    <select
                      name="status"
                      value={sampleTrackingLogFormData.status}
                      onChange={handleSampleTrackingLogInputChange}                                
                      className={`w-full text-sm pl-2 py-1 outline-none bg-white border focus:border-master_color-200 rounded
                        ${isValidStatus? 'border-slate-400':'border-red-600'}
                        `}
                    >
                      <option value="0" disabled={true}>
                        -- change status -- 
                      </option>
                      {sampleStatusList.map((s) => (
                        <option key={s.id} value={s.name}>                          
                            {s.name}                   
                        </option>
                      ))}
                    </select>      
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Remark</div>
                  <div className="col-span-2">
                    <textarea
                      type="text"
                      name="remark"
                      value={sampleTrackingLogFormData.remark}
                      onChange={handleSampleTrackingLogInputChange}                      
                      className={`w-full h-20 text-base pl-2 py-1 outline-none bg-white border border-slate-400 focus:border-master_color-200 rounded`}                                      
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Date</div>
                  <div className="col-span-2">
                    <input  
                      type="date"                      
                      name="updatedAt"
                      value={sampleTrackingLogFormData.updatedAt}
                      onChange={handleSampleTrackingLogInputChange}                                          
                      className={`w-full text-sm pl-2 py-1 outline-none bg-white border focus:border-master_color-200 rounded
                        ${isValidUpdatedAt ? 'border-slate-400':'border-red-600'}
                        `}                      
                    >                                          
                    </input>
                  </div>
                </div>                
              </div>
              
              <div className={`col-span-2 ${operationMode === 'new' ? 'hidden':''}`}>
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Sample Status History
                </div>
                <div className="ag-theme-quartz h-full pt-1 w-10/12">
                  <AgGridReact
                    ref={sampleStatusTableRef}
                    columnDefs={sampleStatusTableColumnDefs}
                    defaultColDef={useMemo(
                      () => ({ suppressMovable: true }),
                      []
                    )}
                    rowData={sampleStatusTableRowData}
                    rowSelection={"single"}
                    rowHeight={30}
                  />                    
                </div>
                <div className={`p-2 pt-3 text-master_color-300 font-semibold ${totalDuration == 0 ? 'hidden':''}`}>Total Duration : <span className="text-black">{Math.round(totalDuration)} days</span></div>
                    
              </div>              
            </div>
          </div>

          <div
            className={`px-4 py-1 ${tab_2_Hide} h-full`}
            id="styled-dashboard"
            role="tabpanel"
          >
            
          </div>
        </div>

        <div className="mb-4 border-b border-gray-200">
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="default-styled-tab"
            data-tabs-toggle="#default-styled-tab-content"
            data-tabs-active-classes="text-master_color-400 hover:text-master_color-400 border-master_color-400 "
            data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
            role="tablist"
          >
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 drop-shadow-sm ${tab_1_active_css}`}
                id="profile-styled-tab"
                data-tabs-target="#styled-profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={() => toggleTab(1)}
              >
                Sample Info
              </button>
            </li>
            <li className="me-2 hidden" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 ${tab_2_active_css}`}
                id="dashboard-styled-tab"
                data-tabs-target="#styled-dashboard"
                type="button"
                role="tab"
                aria-controls="dashboard"
                aria-selected="false"
                onClick={() => toggleTab(2)}
              >
                Item List
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sample;