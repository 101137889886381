import { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { Popover } from "react-tiny-popover";
import { AgGridReact } from "ag-grid-react";
import { getActiveAssortmentList, getActiveAssortmentListByClusterId } from "../../service/AssortmentService";
import { getAllActivePlanList } from "../../service/PlanService";
import { getActiveClusterListByPlanId } from "../../service/ClusterService";
import { getLatestPlanValueListByPlanId } from "../../service/PlanValueService";

function AssortmentPopover({onSelectAssortment,isAssortmentClear,setAssortCode}) {

  const loggedUser = JSON.parse(
    atob(sessionStorage.getItem("x$sk72hyQwersgyhk"))
  );

  const [isOpen, setIsOpen] = useState(false);

  const[assortmentCode, setAssortmentCode] = useState(""); 

  const[basicInputData , setBasicInputData] = useState({
    planId: 0,
    planValueId: 0,
    planValue: '',
    clusterId: 0
  })
  
  const setSelectedAssortment = (assortment) =>{ 
    onSelectAssortment(assortment)
  }

  // plan drop down box
  const[activePlanList, setActivePlanList] = useState([]);

  const fetchActivePlanList = async () => {
    try {
      const req = await getAllActivePlanList(loggedUser.token);
      setActivePlanList(req.data);      
    } catch (error) {
      console.log(error);
    }
  }
  
  // fetch plan list to dropdown
  useEffect(() => {
    fetchActivePlanList();
  }, []);

  useEffect(() => {
    setAssortmentCode(setAssortCode);    
  }, [setAssortCode]);

  // cluster drop down box
  const [activeClusterList, setActiveClusterList] = useState([])

  const fetchActiveClusterList = async(planId) =>{
    try{
      const req = await getActiveClusterListByPlanId(loggedUser.token,planId)
      setActiveClusterList(req.data)
    }catch(error){
      console.error("AssortmentPopover -> fetchActiveClusterList: ", error);
    }
  }

  // plan value field
  const fetchPlanValue = async(planId) =>{
    try{
      const res = await getLatestPlanValueListByPlanId(loggedUser.token,planId)
      
      setBasicInputData((prevState) => ({
        ...prevState,
        planId: planId,
        planValueId: res.data.id,
        planValue: 'Rs.'+res.data.valueOfInstallment*res.data.noOfInstallment,
      }))
    }catch(error){
      console.error("AssortmentPopover -> fetchActiveClusterList: ", error);
    }
  }
  

  // Assortment List Table
  const assortmentTableRef = useRef();
  const [paginationlHide, setPaginationlHide] = useState(true);
  const [assortmentTableRowData, setAssortmentTableRowData] = useState([]);

  const [assortmentTableColumnDefs, setassortmentTableColumnDefs] = useState([
    {
      colId: "id",
      valueGetter: (r) => r.data.id,
      //flex: 1,
      hide: true,
    },    
    {
      colId: "code",
      headerName: "Code",
      valueGetter: (r) => r.data.code,
      filter: true,
      flex: 0.5,
    },     
  ]);

  const fetchAssortmentTableData = async (clusterId) => {
    try {
      const res = await getActiveAssortmentListByClusterId(loggedUser.token,clusterId);      
      if (res !== undefined) {        
        setAssortmentTableRowData(res.data);
      } else {
        setAssortmentTableRowData([]);
      }
    } catch (error) {
      console.error("AssortmentPopover -> fetchAssortmentTableData: ", error);
    }
  };
  
    
  // handlers
  const handleBasicInputChange = (e) => {
    const { name, value } = e.target;
    setBasicInputData((prevState) => ({ ...prevState, [name]: value }));

    if(name == 'planId'){
      setBasicInputData((prevState)=>({
        ...prevState,
        clusterId: 0
      }))

      fetchPlanValue(value) 
      fetchActiveClusterList(value)
      setAssortmentTableRowData([]);         
    }

    if(name == 'clusterId'){
      fetchAssortmentTableData(value)      
    }
  }
  
  const handledOnRowClick = useCallback( async() => {
    const selectedRows = assortmentTableRef.current.api.getSelectedRows(); 
    
    if (selectedRows.length === 1) {      
      setAssortmentCode(selectedRows[0].code)      
      setSelectedAssortment({        
        id: selectedRows[0].id,
        code: selectedRows[0].code,
        planValueId: basicInputData.planValueId,
        value: basicInputData.planValue
      });
     
    }
  }, [basicInputData]);

  const handledOnRowDoubleClick = useCallback( async() => {
    const selectedRows = assortmentTableRef.current.api.getSelectedRows();
    
    if (selectedRows.length === 1) {      
      setAssortmentCode(selectedRows[0].code)      
      setSelectedAssortment({
        id: selectedRows[0].id,
        code: selectedRows[0].code,
        planValueId: basicInputData.planValueId,
        value: basicInputData.planValue
      });
    }
    setIsOpen(false)
  }, [basicInputData]);

  

  return ( 
    <div>
      <Popover
        isOpen={isOpen}
        positions={["right"]} 
        padding={2}
        onClickOutside={() => setIsOpen(false)}
        content={
          <div className="w-80 h-auto pb-2 absolute -mt-4 top-0.5 left-0.5 bg-white border border-slate-400 rounded-lg">
            <div >
              <div id="row-1-col-1">
                <div className="grid grid-cols-3 mt-2 px-2">
                  <div className="pt-0.5 font-sans text-sm drop-shadow">Plan</div>
                  <div className="col-span-2">
                    <select
                      name="planId"
                      value={basicInputData.planId}
                      onChange={handleBasicInputChange}                      
                      className={`w-full pl-1 py-0.5 text-sm outline-none bg-white border border-slate-400 focus:border-master_color-200 rounded`} 
                    >
                      <option value="0" disabled>-- select --</option>
                      {activePlanList.map((plan) => (
                        <option key={plan.id} value={plan.id}>
                          {plan.code}
                        </option>
                      ))}               
                    </select>
                  </div>
                </div>               
              </div>
              
              <div id="row-1-col-2">
                <div className="grid grid-cols-3 mt-2 px-2">
                  <div className="pt-0.5 font-sans text-sm drop-shadow">Value</div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="planValue"
                      value={basicInputData.planValue}  
                      onChange={handleBasicInputChange}                       
                      className={`w-full pl-1 py-0.5 text-sm outline-none bg-gray-100 border border-slate-400 focus:border-master_color-200 rounded `} 
                    />            
                  </div>
                </div>
              </div>
              
              <div id="row-2-col-1">
                <div className="grid grid-cols-3 mt-2 px-2">
                  <div className="pt-0.5 font-sans text-sm drop-shadow">Cluster</div>
                  <div className="col-span-2">
                    <select
                      name="clusterId" 
                      value={basicInputData.clusterId}
                      onChange={handleBasicInputChange}                    
                      className={`w-full pl-1 py-0.5 text-sm outline-none bg-white border border-slate-400 focus:border-master_color-200 rounded `} 
                    >
                      <option value="0" disabled>-- select --</option>
                      {activeClusterList.map((cluster) => (
                        <option key={cluster.id} value={cluster.id}>
                          {cluster.code}
                        </option>
                      ))}   
                    </select>
                  </div>
                </div>    
              </div>
             
              <div id='row-3-col-1' className="col-span-2"><hr className="mt-1 border"></hr></div>
              <div id="row-4-col-1">
                <div className="grid grid-cols-3 mt-2 px-2"> 
                <div className="pt-1.5 font-sans text-sm drop-shadow">Assortment</div>                 
                  <div className="col-span-2">
                    <div className="ag-theme-quartz h-40">                
                      <AgGridReact
                        ref={assortmentTableRef}
                        columnDefs={assortmentTableColumnDefs}
                        rowData={assortmentTableRowData} 
                        headerHeight={35}                  
                        rowHeight={28}
                        pagination={!paginationlHide}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[5, 10, 15]}
                        defaultColDef={useMemo(() => ({ suppressMovable: true }), [])}
                        rowSelection={"single"}
                        onSelectionChanged={handledOnRowClick} 
                        onRowDoubleClicked={handledOnRowDoubleClick}                            
                      />                
                    </div>
                  </div>
                </div> 
              </div>
              
            </div>
            
              
          </div>
              
        }
      >
        <input  
          type="text"                      
          name="customerId"
          placeholder="Required"
          readOnly={true}
          onClick={() => setIsOpen(true)}     
          className={`w-full text-sm font-semibold pl-2 py-1 outline-none bg-white border focus:border-master_color-200 border-slate-400 rounded`}
          value={isAssortmentClear ? '': assortmentCode }
        >                                          
        </input>
        
      </Popover>
    </div>
   );
}

export default AssortmentPopover;