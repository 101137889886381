import { Link } from 'react-router-dom'

function Home() {
    return ( 
        <div>
            <div className='p-10 hidden'>
                <Link to='/terminal/category'>Category</Link><br/>       
                <Link to='/terminal/item'>Item</Link><br/>
                <Link to='/terminal/plan'>Plan</Link><br/>
                <Link to='/terminal/cluster'>Cluster</Link><br/>
                <Link to='/terminal/assortment'>Assortment</Link><br/>
                <Link to='/terminal/route'>Route Module</Link><br/>       
                <Link to='/terminal/customer'>Customer Module</Link><br/>
            </div>
            <div className='p-10 hidden'>                
                <Link to='/terminal/sample'>Sample Module</Link><br/> 
                <Link to='/terminal/employee'>Employee Module</Link><br/>               
            </div>
            <div className='p-10 hidden'>
                <Link to=''>Order Processing Module</Link><br/>       
                <Link to=''>Supplier </Link><br/>                              
            </div>
        </div>
        
     );
}

export default Home;