import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './page/Login';
import Loading from './page/Loading';
import Terminal from './page/Terminal';
import Home from './page/Home';
import Dashboard from './page/Dashboard';
import Item from './page/Item';
import Category from './page/Category';
import Plan from './page/Plan';
import Cluster from './page/Cluster';
import Rout from './page/Route';
import Customer from './page/Customer';
import Assortment from './page/Assortment';
import Sample from './page/Sample';
import Employee from './page/Employee';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/loading' element={<Loading/>}/>
          <Route path='/terminal' element={<Terminal/>}>
            <Route index element={<Home/>}/>  
            <Route path='home' element={<Home/>}/>            
            <Route path='dashboard' element={<Dashboard/>}/>
            <Route path='category' element={<Category/>}/>                  
            <Route path="item" element={<Item/>}/>
            <Route path='plan' element={<Plan/>}/>
            <Route path='cluster' element={<Cluster/>}/>
            <Route path='assortment' element={<Assortment/>}/>
            <Route path='route' element={<Rout/>}/>
            <Route path='customer' element={<Customer/>}/> 
            <Route path='sample' element={<Sample/>}/>
            <Route path='employee' element={<Employee/>}/>          
          </Route>          
        </Routes>
      </BrowserRouter>
  );
}

export default App;
